import { React } from "react";

const ORSGridDetailItem = ({ title, value }) => {
  return (
		<div className="w-full h-full flex items-stretch">
			<div className="grow flex items-stretch flex-col">
				<h4 className="bg-brg text-white text-[11px] uppercase px-3 py-[6px] font-bold tracking-[0.1em]">{title}</h4>
				<div className="grow bg-secondary text-black border-t-[1px] border-t-white text-md py-2 px-3 font-mono">{value}</div>
			</div>
		</div>
  );
};

export default ORSGridDetailItem;
