import { React } from "react";
import { Link } from "react-router-dom";
import { useFetchData } from "helpers/utils";
import { Button } from "components/globals/ui/input/Button";

const ContactBtn = ({ userId, username, labelSuffix }) => {
	const isUsernameQuery = Boolean(username);

	const endpoint = isUsernameQuery
		? `${process.env.REACT_APP_SERVER_URI}/api/users/publicUserByUsername/${username}`
		: `${process.env.REACT_APP_SERVER_URI}/api/users/publicUserById/${userId}`;

	const dependencies = isUsernameQuery ? [username] : [userId];

	const {
		data: user,
		loading,
		error,
	} = useFetchData(endpoint, dependencies);

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	return (
		<>
			{user.allowEmail && (
				<Link to={`mailto:${user.email}`}>
					<Button variant="dark">
						Contact {user.username} {labelSuffix || null}
					</Button>
				</Link>
			)}
		</>
	);
};

export default ContactBtn;
