import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getEntryTypePath } from "types/baseTypes";

// Thunks
export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/users/login`,
        { email, password },
        config
      );

      // store user's token in local storage
      localStorage.setItem(
        "userToken",
        JSON.stringify({
          token: data.userToken,
          expiry: new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
        })
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);


export const updateUserPassword = createAsyncThunk(
  "auth/update",
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/users/update-password`,
        formData,
        config
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateUserSetting = createAsyncThunk(
  "auth/updateSetting",
  async ({ type, formData }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/users/settings/update/${type}`,
        formData,
        config
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateUserSettingImage = createAsyncThunk(
  "auth/updateSettingImage",
  async ({ type, formData }, { rejectWithValue }) => { 
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/formData",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/users/settings/update/${type}`,
        formData,
        config
      );

      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/users/register`,
        formData,
        config
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Utility function to check token expiration
const isTokenExpired = (token) => {
  const tokenData = JSON.parse(token);
  return new Date().getTime() > tokenData.expiry;
};

// Initialize userToken from local storage if not expired
let userToken = localStorage.getItem("userToken");
if (userToken) {
  const tokenData = JSON.parse(userToken);
  if (isTokenExpired(userToken)) {
    localStorage.removeItem("userToken");
    userToken = null;
  } else {
    userToken = tokenData.token;
  }
} else {
  userToken = null;
}

const initialState = {
  loading: false,
  userInfo: null,
  userToken,
  isLoggedIn: !!userToken,
  error: null,
  success: false,
  carSuccess: false,
  carError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userToken");
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.isLoggedIn = false;
      state.error = null;
      state.carSuccess = false;
      state.carError = null;
    },
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
      state.isLoggedIn = !!payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // login user
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000; // 30 days from now
        const tokenData = {
          token: payload.userToken,
          expiry: expirationTime,
        };
        localStorage.setItem("userToken", JSON.stringify(tokenData));
        state.loading = false;
        state.userInfo = payload;
        state.userToken = payload.userToken;
        state.isLoggedIn = true;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.isLoggedIn = false;
      })
      // register user
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const newGarageCar = createAsyncThunk(
  "garage/car/create",
  async (params, { rejectWithValue }) => {
    try {
      const token = JSON.parse(localStorage.getItem("userToken"));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/garage/car/create`,
        params,
        config
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const createEntry = createAsyncThunk(
  "entry/create",
  async (params, { rejectWithValue }) => {
    try {
      const token = JSON.parse(localStorage.getItem("userToken"));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token.token}`,
        },
      };

      // for (let [key, value] of params.data.entries()) {
      //   console.log(`${key}:`, value);
      // }
            
      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/${getEntryTypePath(
          params.entry_type
        )}/create`,
        params.data,
        config
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const updateEntry = createAsyncThunk(
  "entry/update",
  async (params, { rejectWithValue }) => {
    try {
      const token = JSON.parse(localStorage.getItem("userToken"));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/${getEntryTypePath(
          params.entry_type
        )}/update`,
        params.data,
        config
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const deleteEntry = createAsyncThunk(
  "entry/delete",
  async (params, { rejectWithValue }) => {
    try {
      const token = JSON.parse(localStorage.getItem("userToken"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URI}/api/${getEntryTypePath(
          params.entry_type
        )}/delete`,
        params,
        config
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue("An error occurred");
      }
    }
  }
);

export const { logout, setCredentials } = authSlice.actions;

export default authSlice.reducer;
