import { useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { BsXCircle } from "react-icons/bs";
import { usePostPane } from "../../providers/PostPaneContext";
import { Link } from "react-router-dom";
import { Button } from "components/globals/ui/input/Button";
import Gallery from "components/globals/image/Gallery";
import parse from "html-react-parser";
import UserBadge from "components/globals/user/badge";
import CarBadge from "components/globals/car/badge";
import IsYoursBadge from "components/globals/IsYoursBadge";
import {TypeBadge, CategoryBadge} from "components/globals/ui/TypeBadge";
import { useSelector, shallowEqual } from "react-redux";
import EntryUserActions from "components/globals/ui/card/EntryUserActions";
import Comments from "components/globals/comments/Comments";
import Likes from "components/globals/likes/Likes";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useLocation } from "react-router-dom";
import ContactBtn from "components/globals/user/ContactBtn";

const PostPane = () => {
  const { isOpen, postPaneData, closePostPane } = usePostPane();
  const paneRef = useRef(null);
  const contentRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (isOpen && paneRef.current) {
      disableBodyScroll(contentRef.current);
     } else {
      clearAllBodyScrollLocks();
    }
    return () => clearAllBodyScrollLocks();
  }, [isOpen]);

  const isLoggedIn = useSelector(
    (state) => state.auth.isLoggedIn,
    shallowEqual
  );
  
  useEffect(() => {
    clearAllBodyScrollLocks();
    closePostPane();
  }, [location?.pathname]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed top-0 left-0 w-[100vw] h-[100vh] z-[50] flex justify-end"
          ref={paneRef}
        >
          {/* Background Overlay */}
          <motion.div
            className="bg-secondary absolute inset-0 z-[1]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            exit={{ opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
            onClick={closePostPane}
          />

          {/* Sliding Pane Content */}
          <motion.div
            ref={contentRef}
            className="
              shrink-0
              bg-white 
              w-[90vw] 
              lg:w-1/2 
              fixed 
              right-0 
              top-0 
              h-full
              overflow-y-auto
              -webkit-overflow-scrolling: touch
              z-[2]"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{
              duration: 0.5,
              ease: [0.33, 1, 0.68, 1],
            }}
            style={{
              WebkitOverflowScrolling: 'touch', // Ensure smooth scrolling on iOS
              overscrollBehavior: 'contain' // Prevent scroll chaining
            }}
          >
            <div className="relative border-b-[1px] border-b-border">
              <CloseButton />
              <Gallery gallery={postPaneData.item.gallery} />
              {isLoggedIn && (
                <div className="absolute z-[2] bottom-3 right-3">
                  <EntryUserActions comparison={postPaneData.item.user_id} item={postPaneData.item} />
                </div>
              )}

              <div className="absolute z-[1] top-3 left-3">
                <div className="my-1 inline-block">
                  <TypeBadge entryType={postPaneData.item.entry_type} type={postPaneData.item.type} />
                  <div>
                    <CategoryBadge entryType={postPaneData.item.entry_type} type={postPaneData.item.type} category={postPaneData.item.category} />
                  </div>
                </div>
                <div>
                  <Likes 
                    entry_type={postPaneData.item.entry_type} 
                    entry_id={postPaneData.item.internal_id}
                  />
                </div>
              </div>
            </div>

            {/* Content */}
            <div className="p-xs">
              <div className="flex items-stretch md:flex-nowrap flex-wrap gap-3">
                {postPaneData.item.car_id && (
                  <div className="p-2 bg-secondary w-full md:w-1/2">
                    <p className="font-bold text-[12px] mb-2">FOR CAR:</p>
                    <CarBadge car_id={postPaneData.item.car_id} />
                  </div>
                )}

                {postPaneData.item.user_id && (
                  <div className="p-2 bg-secondary w-full md:w-1/2">
                    <p className="font-bold text-[12px] mb-2">POSTED BY:</p>
                    <UserBadge userId={postPaneData.item.user_id} />
                  </div>
                )}
              </div>

              <div className="my-1">
                <IsYoursBadge comparison={postPaneData.item.user_id} />
              </div>

              <h2 className="text-3xl font-bold my-3">{postPaneData.item.title}</h2>
              
              {(postPaneData?.item?.type === "listing" || postPaneData?.item?.type === "want") && (
                <>
                  <ContactBtn userId={postPaneData?.item.user_id} labelSuffix=" about this listing" />
                </>
              )}


              {postPaneData?.item?.body && (
                <div className="mt-xs mb-md ors-rich-text">{parse(postPaneData?.item?.body)}</div>
              )}
              
              <div className="my-3">
                {postPaneData.item.internal_id && (
                  <Link to={`/post/${postPaneData.item.internal_id}`} onClick={closePostPane}>
                    <Button variant="outline">View Details</Button>
                  </Link>
                )}
              </div>

              <Comments 
                entry_type={postPaneData.item.entry_type} 
                entry_id={postPaneData.item.internal_id}
              />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PostPane;


const CloseButton = () => {
  const { closePostPane } = usePostPane();

  return (
    <Button
      onClick={closePostPane}
      className="
        cursor-pointer
        flex
        items-center
        justify-center
        w-[30px]
        h-[30px]
        rounded-full
        transition
        absolute
        top-5
        right-5
        z-[2]
        bg-black
      "
    >
      <BsXCircle className="text-3xl text-white shrink-0" />
    </Button>
  );
};