import { React } from "react";
import { useParams, Link } from "react-router-dom";
import PSPage from "components/globals/ui/layout/Page"
import { formatDate, useFetchAuthedData, formatTitle } from "helpers/utils";
import parse from "html-react-parser";
import IsYoursBanner from 'components/globals/IsYoursBanner'
import Gallery from "components/globals/image/Gallery";
import Comments from "components/globals/comments/Comments";
import UserBadge from "components/globals/user/badge";
import Likes from "components/globals/likes/Likes";
import {TypeBadge} from "components/globals/ui/TypeBadge";
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import ORSGridDetailItem from "components/globals/ui/ORSGridDetailItem";
import { Button } from "components/globals/ui/input/Button";
import ContactBtn from "components/globals/user/ContactBtn";

const EventPage = () => {
	const { id } = useParams();

	// double entry, entry.entry & entry.user
	const { data: entry, loading, error } = useFetchAuthedData(
		`event/${id}`,
		[id],
	);

	const sections = [
		{
			params: {
				elementId: "userFeed", 
				headingTitle: "Other Events",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `event`,
				omit: entry?.entry?.internal_id,
				limit: "12",
				type: "event", 
				pagination: true,
			},
			displayOptions: {
				layout: "card",
				yoursBadge: false,
				typeBadge: true,
				categoryBadge: false,
				userBadge: false,
				carBadge: false,
				carDetails: false,
				dateDetails: false,
				rowColumns: true,
				mobileCarousel: false,
				likes: true,
			}
		}
	]

	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	return (
		<>
			<PSPage>

				<IsYoursBanner label="Your Event" entry={entry.entry} />

				<div className="flex items-start flex-wrap md:flex-nowrap gap-sm">

					<div className="md:sticky md:top-[100px] w-full md:w-1/2 rounded-default overflow-hidden">
						<Gallery gallery={entry.entry.gallery} />
					</div>

					<div className="w-full md:w-1/2 mt-xs md:mt-0">
						
						<div className="my-1 inline-block">
							<TypeBadge entryType={entry.entry.entry_type} type={entry.entry.type} category={entry.entry.category} />
						</div>
						
						<h1 className="text-3xl font-bold">{ formatTitle(entry.entry.title) }</h1>
	
						<div>
							<Likes 
								entry_type={entry.entry.entry_type} 
								entry_id={entry.entry.internal_id}
							/>
						</div>

						
						{entry?.entry?.body && (
							<div className="mt-xs mb-md ors-rich-text">{parse(entry?.entry?.body)}</div>
						)}

						{entry.entry.user_id && (
							<div className="p-2 bg-secondary mb-3 w-full md:w-1/2 rounded-lg">
								<p className="font-bold text-[12px] mb-1">EVENT ORGANIZER:</p>
								<UserBadge userId={entry.entry.user_id} />
								
								<>
									<ContactBtn userId={entry.entry.user_id} labelSuffix=" about this event" />
								</>

							</div>
						)}

						<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-2">
							{entry.entry.event_date && entry.entry.event_type === "single" && (
								<ORSGridDetailItem title="Event Date" value={formatDate(entry.entry.event_date)} />
							)}
							{entry.entry.recurring_frequency && entry.entry.event_type === "recurring" && (
								<ORSGridDetailItem title="Event Date" value={entry.entry.recurring_frequency} />
							)}
							{entry.entry.location && (
								<ORSGridDetailItem title="Location" value={entry.entry.location} />
							)}
							{entry.entry.event_time && (
								<ORSGridDetailItem title="Time" value={entry.entry.event_time} />
							)}
							{entry.entry.category && (
								<ORSGridDetailItem title="Category" value={entry.entry.category} />
							)}
							{entry.entry.type && (
								<ORSGridDetailItem title="Type" value={entry.entry.type} />
							)}
						</div>



						<Comments 
							entry_type={entry.entry.entry_type} 
							entry_id={entry.entry.internal_id}
						/>

					</div>

				</div>

				{entry.entry.location_url && entry.entry.location_url.startsWith('https://') && (
					<iframe 
						className="rounded-lg overflow-hidden my-md"
						title="iframe"
						src={entry.entry.location_url} 
						width="100%" 
						height="450" 
						loading="lazy" 
						referrerPolicy="no-referrer-when-downgrade">
					</iframe>
				)}

				{sections.map(({params, displayOptions}) => (
					<ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
				))}


			</PSPage>
		</>
		
	);
};

export default EventPage;
