export const Schema = {
  private: "",
  gallery: "",
  banners: "",
  title: "",
  body: "",
  type: "",
  category: "",
	group_id: "",
  event_type: "",
	event_date: "",
  event_time: "",
	recurring_frequency: "",
	location: "",
	location_url: "",
  attendee_limit: "",
  allow_requests: "",
};

export const associationTypes = ["groups"];

export const associationState = {
  groups: "",
};

export const idMap = {
  groups: "group",
};

export const types = [
  {
    key: "meetup",
    label: "Meetup",
  },
  {
    key: "drive",
    label: "Drive",
  },
  {
    key: "other",
    label: "Other",
  },
];

export const categories = [
  {
    type: "meetup",
    items: [
      {
        key: "carsandcoffee",
        label: "Cars & Coffee",
      },
      {
        key: "popup",
        label: "Popup",
      },
      {
        key: "show",
        label: "Show",
      },
    ],
  },
  {
    type: "drive",
    items: [],
  },

  {
    type: "other",
    items: [
      {
        key: "fundraiser",
        label: "Fundraiser",
      },
      {
        key: "nonprofit",
        label: "Non-Profit",
      },
      {
        key: "tech",
        label: "Tech Session",
      },
      {
        key: "other",
        label: "Other",
      },
    ],
  },
];
