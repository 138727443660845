import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import User from "components/globals/user/badge";
import parse from "html-react-parser";
import PSPage from "components/globals/ui/layout/Page"
import { useFetchData, formatDate, scrollToComments } from "helpers/utils";
import IsYoursBanner from 'components/globals/IsYoursBanner';
import {ORSMasterListing} from "components/globals/ORSMasterListing"
import ORSYearMakeModel from "components/globals/car/ORSYearMakeModel";
import ORSDetailPageMarquee from "components/globals/layout/detail/marquee";
import Comments from "components/globals/comments/Comments";
import {
  getTypeString,
  getCategoryString,
} from "types/carTypes";
import Likes from "components/globals/likes/Likes";
import { FaRegCommentAlt } from "react-icons/fa";
import Gallery from "components/globals/image/Gallery";
import {CategoryBadge} from "components/globals/ui/TypeBadge";
import ORSGridDetailItem from "components/globals/ui/ORSGridDetailItem";

const UserCar = () => {
  const [formattedDate, setFormattedDate] = useState(0);
  const { id } = useParams();

  // double entry, entry.entry & entry.user
  const { data: entry, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/car/${id}`,
		[id], // This dependency ensures re-fetch when id changes
	);

  useEffect(() => {
    setFormattedDate(formatDate(entry?.entry?.created_at));
  }, [entry]);

  const getSections = (entryData) => [
    {
      params: {
				elementId: "carFeed", 
				headingTitle: "Feed",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/car/${entryData?.entry?.internal_id}`,
				omit: "none",
				limit: "12",
				type: "post", 
				pagination: true,
			},
      displayOptions: {
        layout: "card",
        yoursBadge: false,
        typeBadge: true,
        categoryBadge: false,
        userBadge: false,
        carBadge: false,
        carDetails: false,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    },
    {
      params: {
				elementId: "otherGarageCars", 
				headingTitle: `Other cars in ${entryData?.user?.username}'s garage`,
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `garage/user/${entryData?.user?.user_id}`,
				omit: entryData?.entry?.internal_id,
				limit: "12",
				type: "car", 
				pagination: true,
			},
      displayOptions: {
        layout: "row",
        yoursBadge: false,
        typeBadge: false,
        categoryBadge: false,
        userBadge: false,
        carBadge: false,
        carDetails: false,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    },
    { 
      params: {
				elementId: "relatedCars", 
				headingTitle: `More ${entryData?.entry?.make} cars.`,
				headingButtonUrl: `/cars/make/${entryData?.entry?.make.toLowerCase()}`,
				headingButtonText: "View All",
				apiPath: `garage/related/make/${entryData?.entry?.make.toLowerCase()}`,
				omit: entryData?.entry?.internal_id,
				limit: "12",
				type: "car", 
				pagination: false,
			},
      displayOptions: {
        layout: "card",
        yoursBadge: true,
        typeBadge: false,
        categoryBadge: false,
        userBadge: true,
        carBadge: false,
        carDetails: true,
        dateDetails: false,
        rowColumns: true,
        mobileCarousel: false,
        likes: true,
      }
    },
  ];

  // Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

  // Generate sections dynamically based on current entry
  const sections = getSections(entry);

  return (
    <>
      <ORSDetailPageMarquee banners={entry.entry.banners} gallery={entry.entry.gallery}>
        {/* Rest of the existing rendering logic */}

        <div className="my-1 inline-block flex items-center gap-2 flex-wrap md:flex-nowrap">
          <CategoryBadge entryType={entry.entry.entry_type} type={entry.entry.type} category={entry.entry.category} />
        </div>
        <h1 className="m-0 text-2xl md:text-3xl font-bold md:mb-xs">{entry.entry.title}</h1>

        <div className="flex items-center mb-3">

          <div className="mr-2">
            <Likes 
              entry_type={entry.entry.entry_type} 
              entry_id={entry.entry.internal_id}
            />
          </div>

          <div 
            className="cursor-pointer" 
            onClick={scrollToComments}
          >
            <FaRegCommentAlt className="text-black text-2xl" />
          </div>

        </div>

        <div className="mb-xs">
          <ORSYearMakeModel entry={{
            year: entry.entry.year,
            make: entry.entry.make,
            model: entry.entry.model,
            trim: entry.entry.trim
          }} />
        </div>

        <div className="text-[12px] tracking-[.2px] mb-xs">Added: <strong>{formattedDate}</strong></div>

        <p className="text-[12px] tracking-[.2px] font-bold mb-1">Owner</p>
        <User 
          noPadding 
          userId={entry.entry.user_id} 
        />

        {entry?.entry?.body && (
          <div className="mt-3 ors-rich-text">{parse(entry?.entry?.body)}</div>
        )}

      </ORSDetailPageMarquee>

      <PSPage>
        <IsYoursBanner label="Your Car" entry={entry.entry} />

        <div className="mt-sm">
          <div className="my-md">
            <h3 className="text-2xl font-bold mb-3">Details</h3>


            <div className="flex items-start flex-wrap md:flex-nowrap">

              <div className="w-full mb-3 md:mr-sm md:w-[60%] rounded-lg overflow-hidden">
                <Gallery gallery={entry.entry.gallery} />
              </div>

              <div className="w-full md:w-[40%] grid grid-cols-1 md:grid-cols-2 gap-2">
                {entry.entry.type && (
                  <ORSGridDetailItem title="Type" value={getTypeString(entry.entry.type)} />
                )}

                {entry.entry.category && (
                  <ORSGridDetailItem title="Category" value={getCategoryString(entry.entry.type, entry.entry.category)} />
                )}

                {entry.entry.mileage && (
                  <ORSGridDetailItem title="Mileage" value={entry.entry.mileage} />
                )}

                {entry.entry.horsepower && (
                  <ORSGridDetailItem title="HP" value={entry.entry.horsepower} />
                )}

                {entry.entry.engine && (
                  <ORSGridDetailItem title="Engine" value={entry.entry.engine} />
                )}

                {entry.entry.torque && (
                  <ORSGridDetailItem title="Torque" value={entry.entry.torque} />
                )}

                {entry.entry.color && (
                  <ORSGridDetailItem title="Color" value={entry.entry.color} />
                )}

                {entry.entry.vin && (
                  <ORSGridDetailItem title="VIN" value={entry.entry.vin} />
                )}
              </div>
              
            </div>

            
          </div>

          {sections.map(({params, displayOptions}) => (
            <ORSMasterListing 
              key={`${params.elementId}-${id}`} 
              params={params} 
              displayOptions={displayOptions} 
            />
          ))}

          <div id="comments">
            <Comments 
              entry_type={entry.entry.entry_type} 
              entry_id={entry.entry.internal_id}
            />
          </div>
        </div>
      </PSPage>
    </>
  );
};

export default UserCar;