import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { ORSMasterListing } from "components/globals/ORSMasterListing"
import PSPage from "components/globals/ui/layout/Page"
import { useFetchData, formatDate } from "helpers/utils";
import ORSDetailPageMarquee from "components/globals/layout/detail/marquee";
import ORSMemberNumber from "components/globals/user/ORSMemberNumber";
import IsYoursBanner from 'components/globals/IsYoursBanner';
import ContactBtn from "components/globals/user/ContactBtn";

const UserProfile = () => {
	const [formattedDate, setFormattedDate] = useState(0);
	const { username } = useParams();

	const { data: user, loading, error } = useFetchData(
		`${process.env.REACT_APP_SERVER_URI}/api/users/publicUser/${username}`,
		[username],
	);

	useEffect(() => {
		setFormattedDate(formatDate(user?.created_at));
	}, [user]);

	// recent listings
	// recent wants

	const sections = [
		{
			params: {
				elementId: "userFeed",
				headingTitle: "Recent Posts",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/user/${user?.user_id}`,
				omit: "none",
				limit: "12",
				type: "post",
				pagination: true,
			},
			displayOptions: {
				layout: "card",
				yoursBadge: false,
				typeBadge: true,
				categoryBadge: false,
				userBadge: false,
				carBadge: false,
				carDetails: false,
				dateDetails: false,
				rowColumns: true,
				mobileCarousel: false,
				likes: true,
			}
		},
		{
			params: {
				elementId: "userGarage",
				headingTitle: `${user?.username}'s Garage`,
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `garage/user/${user?.user_id}`,
				omit: "none",
				limit: "12",
				type: "car",
				pagination: true,
			},
			displayOptions: {
				layout: "row",
				yoursBadge: false,
				typeBadge: false,
				categoryBadge: false,
				userBadge: false,
				carBadge: false,
				carDetails: false,
				dateDetails: false,
				rowColumns: true,
				mobileCarousel: false,
				likes: true,
			}
		},
		{
			params: {
				elementId: "userListings",
				headingTitle: "Listings For Sale",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/type/listing/user/${user?.user_id}`,
				omit: "none",
				limit: "12",
				type: "post",
				pagination: true,
			},
			displayOptions: {
				layout: "card",
				yoursBadge: false,
				typeBadge: false,
				categoryBadge: false,
				userBadge: false,
				carBadge: false,
				carDetails: false,
				dateDetails: false,
				rowColumns: true,
				mobileCarousel: false,
				likes: true,
			}
		},
		{
			params: {
				elementId: "userWants",
				headingTitle: "Want-Ads",
				headingButtonUrl: "",
				headingButtonText: "",
				apiPath: `post/type/want/user/${user?.user_id}`,
				omit: "none",
				limit: "12",
				type: "post",
				pagination: true,
			},
			displayOptions: {
				layout: "row",
				yoursBadge: false,
				typeBadge: false,
				categoryBadge: false,
				userBadge: false,
				carBadge: false,
				carDetails: false,
				dateDetails: false,
				rowColumns: true,
				mobileCarousel: false,
				likes: true,
			}
		},
	]

	// Loading & error states
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	return (
		<>

			<ORSDetailPageMarquee banners={user?.banners} gallery={user?.gallery}>
				<h1 className="relative text-3xl font-bold">{user.username}</h1>

				<ORSMemberNumber number={user.memberNumber} />
				<p>User since: {formattedDate}</p>

				<div className="my-3">
					<ContactBtn username={user?.username} labelSuffix="" />
				</div>

				{user?.bio && (
					<div className="max-w-[500px]">{parse(user?.bio)}</div>
				)}
			</ORSDetailPageMarquee>

			<PSPage>

				<IsYoursBanner label="You" entry={user} user />


				{sections.map(({ params, displayOptions }) => (
					<ORSMasterListing key={params.elementId} params={params} displayOptions={displayOptions} />
				))}

			</PSPage>
		</>
	);
};

export default UserProfile;
